import { from, map, Observable } from "rxjs";
import Animal from "../models/animal";
import Customer from "../models/customer";
import Doctor from "../models/doctor";
import Employee from "../models/employee";
import Resource from "../models/resource";
import Schedule from "../models/schedule";
import TimeSlot from "../models/time-slot";
import TimeTable from "../models/time-table";
import Settings from "../models/settings";
import User from "../models/user";
import VisitType from "../models/visit-type";
import HttpService from "./http.service";
import UtilityService from "./utility.service";

type EmployeeFromApi = {
  confiramtion_token: string;
  createTimestamp: Date;
  email: string;
  enabled: boolean;
  id: number;
  last_login: Date;
  roles: string;
  user: {
    firstName: string;
    lastName: string;
  }
};

interface DoctorFromApiWithEmail {
  application_user: {
    email: string;
  }
}

interface DoctorFromApiWithOtherData {
  doctor: Doctor;
}

type DoctorFromApi = [DoctorFromApiWithOtherData, DoctorFromApiWithEmail, ...VisitType[]];


type VisitTypeFromApi = VisitType & { resource_id: Resource };

export type ScheduleCreated = { "Created schedule id": number, warnings?: { warning: string }[] }

interface CustomerFromApiWithEmail {
  user: {
    email: string;
  }
}

type CustomerFromApi = Customer & CustomerFromApiWithEmail;
export default class ApiService {

  static selectedDoctor: Doctor;
  static selectedEmployee: Employee;
  // ANIMAL
  static getAnimalsOfUser(userId: string): Observable<Animal[]> {
    return from(HttpService.get(`animal/of-user/${userId}`)) as Observable<Animal[]>;
  }

  static addAnimal(animal: Animal): Promise<Animal> {
    return HttpService.post(`animal`, animal) as Promise<Animal>;
  }

  static deleteAnimal(id: number): Promise<Animal> {
    return HttpService.delete(`animal/${id}`) as Promise<Animal>;
  }

  static editAnimal(animal: Animal, id: number): Promise<Animal> {
    return HttpService.put(`animal/${id}`, animal) as Promise<Animal>;
  }


  // RESOURCES
  static getAllResources(): Promise<Resource[]> {
    return HttpService.get(`resource`) as Promise<Resource[]>;
  }

  static addResource(resource: Resource): Promise<Resource> {
    return HttpService.post(`resource`, resource) as Promise<Resource>;
  }

  static deleteResource(id: number): Promise<Resource> {
    return HttpService.delete(`resource/${id}`) as Promise<Resource>;
  }

  static editResource(resource: Resource, id: number): Promise<Resource> {
    return HttpService.put(`resource/${id}`, resource) as Promise<Resource>;
  }

  // Settings
  static getAllSettings(): Promise<Settings[]> {
    return HttpService.get(`settings`) as Promise<Settings[]>;
  }

  static editSettings(settings): Promise<Resource> {
    return HttpService.put(`settings/`, settings) as Promise<Resource>;
  }

  // VISIT TYPES
  static getAllVisitTypes(): Observable<VisitType[]> {
    return from(HttpService.get(`specialization`) as Promise<VisitTypeFromApi[]>);
  }

  static addVisitType(visitType: VisitType): Promise<VisitType> {
    return HttpService.post(`specialization`, visitType) as Promise<VisitType>;
  }

  static deleteVisitType(id: number): Promise<VisitType> {
    return HttpService.delete(`specialization/${id}`) as Promise<VisitType>;
  }

  static editVisitType(visitType: VisitType, id: number): Promise<VisitType> {
    return HttpService.put(`specialization/${id}`, visitType) as Promise<VisitType>;

  }

  // DOCTORS
  static getAllDoctors(): Observable<Doctor[]> {
    return from(HttpService.get(`doctor`) as Promise<(DoctorFromApi[])>)
      .pipe(
        map((doctors: (DoctorFromApi[])) => {
          const mappedDoctors = doctors.map(doctor => {
            const specializations = (doctor.slice(2) as unknown as ({ specialization: VisitType })[]).map(visitType => visitType.specialization);
            return {
              ...doctor[0].doctor,
              ...doctor[1].application_user,
              specializations
            } as Doctor;
          });
          return mappedDoctors;
        })
      );
  }

  static getDoctorAccount(id: number): Observable<Doctor> {
    return this.getAllDoctors().pipe(
      map(doctors => {
        return doctors.find(doctor => doctor.application_user === id)!;
      }
      ));
  }

  static getDoctorById(id: number): Observable<Doctor> {
    return from(HttpService.get(`doctor/${id}`) as Promise<(Doctor)>);
  }

  static addDoctor(doctor: Doctor): Promise<Doctor> {
    return HttpService.post(`doctor`, doctor) as Promise<Doctor>;
  }

  static deleteDoctor(id: number): Promise<Doctor> {
    return HttpService.get(`doctor/deactivate/${id}`) as Promise<Doctor>;
  }

  static editDoctor(doctor: Doctor, id: number): Promise<Doctor> {
    return HttpService.put(`doctor/${id}`, doctor) as Promise<Doctor>;
  }

  // CLIENTS
  static getAllCustomers(): Observable<Customer[]> {
    return from(HttpService.get(`customer`) as Promise<CustomerFromApi[]>).pipe(
      map((customers: (CustomerFromApi[])) => {
        const mappedCustomers = customers.map(customer => {
          return {
            ...customer,
            ...customer.user
          } as Customer;
        });
        return mappedCustomers;
      })
    );
  }

  static addCustomer(customer: Customer): Promise<number> {
    return HttpService.post(`customer`, { ...customer, profile_complete: 1 }) as Promise<number>;
  }

  static deleteCustomer(id: number): Promise<Customer> {
    return HttpService.delete(`customer/${id}`) as Promise<Customer>;
  }

  static getCustomerById(id: string): Observable<Customer> {
    return from(HttpService.get(`customer/${id}`) as Promise<Customer>);
  }

  static getCustomerByUserId(id: string): Promise<Customer> {
    return HttpService.get(`customer/by-user/${id}`) as Promise<Customer>;
  }

  static editCustomer(customer: Customer, id: number): Promise<Customer> {
    return HttpService.put(`customer/${id}`, { ...customer, profile_complete: 1 }) as Promise<Customer>;
  }
  // USERS
  static getUserById(id: number): Observable<User> {
    return from(HttpService.get(`user/${id}`)) as Observable<(User)>;
  }
  // EMPLOYEES
  static getAllEmployees(): Observable<Employee[]> {
    const staff$ = from(HttpService.get(`user/staff`)) as Observable<(EmployeeFromApi[])>;
    return staff$.pipe(
      map((employees: (EmployeeFromApi[])) => {
        const mappedEmployees = employees.map(employee => {
          return {
            ...employee,
            firstName: employee.user?.firstName,
            lastName: employee.user?.lastName
          } as Employee;
        });
        return mappedEmployees;
      }
      )
    );
  }

  static addEmployee(employee: Employee): Observable<Employee> {
    return from(HttpService.post(`user/staff`, employee) as Promise<Employee>);
  }

  static editEmployee(employee: Employee, id: number): Observable<Employee> {
    return from(HttpService.put(`user/staff/${id}`, { ...employee, user: { firstName: employee.firstName, lastName: employee.lastName } }) as Promise<Employee>);
  }

  //TIMESLOTS
  static getAvailableTimeslotsForDateAndVisitType(date: Date, visitType: number): Observable<TimeSlot[]> {
    return (from(HttpService.get(`time-table/of-date/${UtilityService.formatDate(date, 'yyyy-MM-DD')}/${visitType}`)).pipe(map((timeTables: TimeSlot[]) => {
      timeTables = ApiService.mapTimeslots(timeTables);
      return timeTables;
    }))) as Observable<TimeSlot[]>;
  }


  static getFirstTimeSlotForDoctorAndSpecialization(doctor: number, specialization: number): Observable<TimeSlot> {
    return (from(HttpService.get(`time-table/first-of-doctor/${doctor}/${specialization}`))
              .pipe(map((timeSlot: TimeSlot) => ApiService.mapTimeslot(timeSlot)))) as Observable<TimeSlot>;
  }

  static getFirstTimeSlotForSpecialization(specialization: number): Observable<TimeSlot> {
    return (from(HttpService.get(`time-table/first/${specialization}`))
              .pipe(map((timeSlot: TimeSlot) => ApiService.mapTimeslot(timeSlot)))) as Observable<TimeSlot>;
  }

  private static mapTimeslot(timeSlot: TimeSlot): TimeSlot {
    return {
      ...timeSlot,
      from: UtilityService.parseTimestampFromBackend(timeSlot.from),
      to: UtilityService.parseTimestampFromBackend(timeSlot.to),
    }
  }

  private static mapTimeslots(timeSlots: TimeSlot[]): TimeSlot[] {
    return timeSlots.map(timeSlot =>
      this.mapTimeslot(timeSlot)
    );
  }


  // TIMETABLES
  static getAllTimeTables(blocking = true): Observable<TimeTable[]> {
    return (from(HttpService.get(`time-table`, blocking)) as Observable<TimeTable[]>).pipe(
      map((timeTables: TimeTable[]) => {
        timeTables = ApiService.mapTimetables(timeTables);
        return timeTables;
      }))
  }

  static getTimeTablesForMonth(month: number, year: number, blocking = true): Observable<TimeTable[]> {
    return (from(HttpService.get(`time-table/after/${month}/${year}`, blocking)) as Observable<TimeTable[]>).pipe(
      map((timeTables: TimeTable[]) => {
        timeTables = ApiService.mapTimetables(timeTables);
        return timeTables;
      }))
  }

  private static mapTimetable(timeTable: TimeTable): TimeTable {
    return {
      ...timeTable,
      createTimestamp: UtilityService.parseTimestampFromBackend(timeTable.createTimestamp!),
      fromTimestamp: UtilityService.parseTimestampFromBackend(timeTable.fromTimestamp),
      toTimestamp: UtilityService.parseTimestampFromBackend(timeTable.toTimestamp),
      refreshTimestamp: UtilityService.parseTimestampFromBackend(timeTable.refreshTimestamp!)
    }
  }

  private static mapTimetables(timeTables: TimeTable[]): TimeTable[] {
    return timeTables.map(timeTable => this.mapTimetable(timeTable));
  }

  static addTimeTable(timeTable: TimeTable): Observable<TimeTable> {
    timeTable.fromTimestamp = UtilityService.parseTimestampToBackend(timeTable.fromTimestamp);
    timeTable.toTimestamp = UtilityService.parseTimestampToBackend(timeTable.toTimestamp);
    return (from(HttpService.post(`time-table`, timeTable)) as Observable<TimeTable>);
  }

  static addBulkTimeTable(timeTable: TimeTable): Observable<TimeTable> {
    timeTable.fromTimestamp = UtilityService.parseTimestampToBackend(timeTable.fromTimestamp);
    timeTable.toTimestamp = UtilityService.parseTimestampToBackend(timeTable.toTimestamp);

    return (from(HttpService.post(`time-table/bulk`, timeTable)) as Observable<TimeTable>);
  }

  static editTimeTable(timeTable: TimeTable, id: number): Observable<TimeTable> {
    if (timeTable.fromTimestamp) {
      timeTable.fromTimestamp = UtilityService.parseTimestampToBackend(timeTable.fromTimestamp);
    }

    if (timeTable.toTimestamp) {
      timeTable.toTimestamp = UtilityService.parseTimestampToBackend(timeTable.toTimestamp);
    }
    return (from(HttpService.put(`time-table/${id}`, timeTable)) as Observable<TimeTable>);
  }

  static deleteTimeTable(id: number): Observable<TimeTable> {
    return (from(HttpService.delete(`time-table/${id}`)) as Observable<TimeTable>);
  }

  static getTimeTablesByDoctorId(id: number): Observable<TimeTable[]> {
    return (from(HttpService.get(`time-table/of-doctor/${id}`)) as Observable<TimeTable[]>).pipe(
      map((timeTables: TimeTable[]) => {
        timeTables = timeTables.map(timeTable => {
          return {
            ...timeTable,
            createTimestamp: UtilityService.parseTimestampFromBackend(timeTable.createTimestamp!),
            fromTimestamp: UtilityService.parseTimestampFromBackend(timeTable.fromTimestamp),
            toTimestamp: UtilityService.parseTimestampFromBackend(timeTable.toTimestamp),
            refreshTimestamp: UtilityService.parseTimestampFromBackend(timeTable.refreshTimestamp!)
          }
        });
        return timeTables;
      }))
  }

  static getWorkingDoctorsIds(date: string): Observable<Doctor[]> {
    return (from(HttpService.get(`time-table/working-doctors/${date}`)) as Observable<Schedule[]>).pipe(
      map(schedules => {
        const docs = schedules.map(sch => {
          return { id: sch.doctor } as Doctor;
        });
        return docs;
      }));
  }

  // SCHEDULES

  static addSchedule(schedule: (Schedule | { specialization: number, customerAnimal: number })): Observable<ScheduleCreated> {
    if (schedule['fromTimestamp']) {
      schedule['fromTimestamp'] = UtilityService.parseTimestampToBackend(schedule['fromTimestamp']);
      schedule['toTimestamp'] = UtilityService.parseTimestampToBackend(schedule['toTimestamp']);
    }
    return from(HttpService.post(`schedule`, { ...schedule, doctor_note: (schedule as Schedule).doctor_note }) as Promise<ScheduleCreated>);
  }

  static editSchedule(schedule: { fromTimestamp?: Date, toTimestamp?: Date, doctor_note?: string, specialization?: number, cancelationTimestamp?: Date }, id: number): Observable<ScheduleCreated> {
    if (schedule.fromTimestamp) {
      schedule.fromTimestamp = UtilityService.parseTimestampToBackend(schedule.fromTimestamp);
    }

    if (schedule.toTimestamp) {
      schedule.toTimestamp = UtilityService.parseTimestampToBackend(schedule.toTimestamp);
    }

    schedule.toTimestamp?.setSeconds(1);
    return from(HttpService.put(`schedule/${id}`, schedule) as Promise<ScheduleCreated>);
  }

  private static mapSchedules(schedules): Schedule[] {
    return schedules.map(schedule => {
      return {
        id: schedule.id,
        fromTimestamp: UtilityService.parseTimestampFromBackend(schedule.fromTimestamp),
        toTimestamp: UtilityService.parseTimestampFromBackend(schedule.toTimestamp),
        specialization: schedule.specialization_id,
        doctor: schedule.doctor_id,
        animal: schedule.animal,
        customer: schedule.customer,
        customerData: { ...schedule.customer_id },
        visitCompleted: schedule.visitCompleted,
        confirmationTimestamp: schedule.confirmationTimestamp,
        cancelationTimestamp: schedule.cancelationTimestamp ? UtilityService.parseTimestampFromBackend(schedule.cancelationTimestamp) : null,
        doctor_note: schedule.doctor_note,
        registeredBy: schedule.registred_by
      }
    }
    )
  }


  static getSchedulesByCustomerId(customerId: number): Observable<Schedule[]> {
    return from(HttpService.get(`schedule/of-customer/${customerId}`)).pipe(map(schedules => this.mapSchedules(schedules)));
  }

  static getAllSchedules(blocking = true): Observable<Schedule[]> {
    return from(HttpService.get(`schedule`, blocking)).pipe(map(schedules => this.mapSchedules(schedules)));
  }

  static getSchedulesForMonth(month: number, year: number, blocking = true): Observable<Schedule[]> {
    return from(HttpService.get(`schedule/after/${month}/${year}`, blocking)).pipe(map(schedules => this.mapSchedules(schedules)));
  }

  static getSchedulesByDoctorId(doctorId: number): Observable<Schedule[]> {
    return from(HttpService.get(`schedule/of-doctor/${doctorId}`)).pipe(map(schedules => this.mapSchedules(schedules)));
  }

  static deactivateSchedule(id: number): Observable<Schedule> {
    return from(HttpService.get(`schedule/deactivate/${id}`) as Promise<Schedule>);
  }

  static deleteSchedule(id: number): Observable<Schedule> {
    return from(HttpService.delete(`schedule/${id}`) as Promise<Schedule>);
  }

}
